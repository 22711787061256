'use strict';
// IE listener fix
if (typeof(UserAgentInfo) != 'undefined' && !window.addEventListener){
    UserAgentInfo.strtBrowser = 1;
}
window.isIE = function() {
  var myNav = navigator.userAgent.toLowerCase();
  return (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

window.jQuery = require('jquery');
window.$ = window.jQuery;
window.TweenMax = require('../../node_modules/gsap/src/uncompressed/TweenMax');
/*window.ScrollTo = require('gsap/src/uncompressed/plugins/ScrollToPlugin');
window.Swiper = require('swiper');
window.isMobile = require('ismobilejs');
window.modernizer = require('./modernizer');
window.ButterScroll = require('butter-scroll');*/

var InfiniteCity_APP = (function(){

    window.InfiniteCity = require('./modules/InfiniteCity');

    // document ready
    $(document).ready(function($) {
        var infiniteCity = document.querySelector('.infiniteCity')
        if (infiniteCity) {
            new InfiniteCity(infiniteCity)
        }

    });

})();

