module.exports=[
    {
        "assets" : {
            "blocks" : [
                "block_big_building.glb",
                "block_l_shape.glb",
                "block_long_double_tree.glb",
                "block_long_triple_tree.glb",
                "block_park.glb",
                "block_q_bert_1.glb",
                "block_q_bert_2.glb",
                "block_roof_top_garden.glb",
                "block_skyscraper.glb",
                "block_tall_long_six_trees.glb",
                "block_triple_tall.glb"
            ],
            "specialblocks" : [
                "block_big_building.glb",
                "block_roof_top_garden.glb",
                "block_skyscraper.glb",
                "block_tall_long_six_trees.glb",
                "block_triple_tall.glb"
            ],
            "roads" : [
                "road_straight.glb",
            ],
            "squares" : [
                "road_square.glb"
            ]
        }
    }
]
