window.assets = require('./IsometricCity_assets.json');
window.FLOOR_SIZE = 100
window.GRID_SIZE = 9
window.ORBIT = false
window.STEP_SIZE = FLOOR_SIZE * 0.899999995
window.KEYBOARD_STEP = 50

var InfiniteCity = function($container){
    var self = this
    // setup
    self.$el = $container
    self.$canvasHolder = self.$el.querySelector('.canvasHolder')
    // elements
    self.camera = null
    self.scene = null
    self.renderer = null
    self.container = null
    self.floor = null
    self.controls = null
    self.txt_loader = null
    self.load_manager = null
    self.gltf_loader = null
    self.stagecenter = null
    self.lastCenterPos = null
    self.floorGroup = null
    self.raycaster = null
    self.mouse = null

    console.log(self.$canvasHolder)
    // init
    self.initCity()
}

InfiniteCity.prototype.initCity = function() {
    var self = this
    console.log(self.$canvasHolder)
    self.container = self.$canvasHolder
    self.stagecenter = new THREE.Vector3(0, 0, 0)
    self.lastCenterPos = new THREE.Vector3(0, 0, 0)
    self.camera = new THREE.PerspectiveCamera(
        60,
        self.container.clientWidth / self.container.clientHeight,
        0.2,
        4000
    )

    self.raycaster = new THREE.Raycaster()
    self.mouse = new THREE.Vector2()
    self.txt_loader = new THREE.TextureLoader()
    self.load_manager = new THREE.LoadingManager()
    self.gltf_loader = new GLTFLoader()
    self.camera.position.set(FLOOR_SIZE * -1.5, FLOOR_SIZE * 3, FLOOR_SIZE * (GRID_SIZE / 3))
    self.stagecenter.x = self.camera.position.x - (FLOOR_SIZE * -1.5)
    self.stagecenter.z = self.camera.position.z - FLOOR_SIZE * (GRID_SIZE / 3)
    self.camera.rotation.order = 'YXZ'
    self.camera.rotation.y = -Math.PI / 4
    self.camera.rotation.x = Math.atan(-1 / Math.sqrt(0.5))
    self.fov = self.camera.fov
    self.zoom = 0.85
    self.camera.fov = self.fov * self.zoom
    self.camera.updateProjectionMatrix()

    self.scene = new THREE.Scene()
    self.scene.fog = new THREE.Fog(0x000000, FLOOR_SIZE / 2, 600)
    self.renderer = new THREE.WebGLRenderer({ antialias: true, physicallyCorrectLights: true })
    self.renderer.setSize(
        self.container.clientWidth,
        self.container.clientHeight
    )
    self.container.appendChild(self.renderer.domElement)
    //self.cameraControls() // disable on production: debug only
    self.controls = new THREE.MapControls( self.camera , self.renderer.domElement )
    self.controls.enableDamping = true
    self.controls.dampingFactor = 0.1
    self.controls.panSpeed = 0.1
    self.controls.enableRotate = false
    self.controls.enableKeys = true
    self.controls.enableZoom = false
    self.controls.keyPanSpeed = 0.1

    var light = new THREE.AmbientLight(0xffffff)
    light.castShadow = true
    self.scene.add(light)

    var hemiLight = new THREE.HemisphereLight(0x22f9f9, 0xffffff, 0.5)
    hemiLight.castShadow = true
    self.scene.add(hemiLight)

    // preload assets
    self.assetsBlocks = assets[0].assets.blocks
    self.assetsSpecialBlocks = assets[0].assets.specialblocks
    self.assetsRoads = assets[0].assets.roads
    self.assetsSquares = assets[0].assets.squares
    self.assetsCount = self.assetsBlocks.length + self.assetsRoads.length + self.assetsSquares.length + self.assetsSpecialBlocks.length
    self.blocksLoaded = []
    self.roadsLoaded = []
    self.squaresLoaded = []
    self.specialBlocksLoaded = []
    self.assetsLoaded = 0

    for (var i = 0; i < self.assetsBlocks.length; i++) {
        self.loadAsset(self.assetsBlocks[i], self.blocksLoaded)
    }

    for (var i = 0; i < self.assetsSpecialBlocks.length; i++) {
        self.loadAsset(self.assetsSpecialBlocks[i], self.specialBlocksLoaded)
    }

    for (var i = 0; i < self.assetsRoads.length; i++) {
        self.loadAsset(self.assetsRoads[i], self.roadsLoaded)
    }

    for (var i = 0; i < self.assetsSquares.length; i++) {
        self.loadAsset(self.assetsSquares[i], self.squaresLoaded)
    }

    // orbit controls - for debugging
    if (ORBIT) {
        self.controls = new OrbitControls(
            self.camera,
            self.renderer.domElement
        )
        self.controls.enablePan = true
        self.controls.enableZoom = false
        self.controls.enableKeys = true
        self.controls.enableRotate = false
        self.controls.enableDamping = true
        self.controls.dampingFactor = .2
        self.controls.panSpeed = 30
        self.controls.screenSpacePanning = true
    }

    // on window resize
    window.addEventListener('resize', function(){
        self.resize()
    })
}

InfiniteCity.prototype.loadAsset = function(filename,destArr) {
    var self = this
    self.gltf_loader.load(
            '/assets/models/'+filename,
        function(object) {
            // called when the resource is loaded
            destArr.push(object)
            self.assetsLoaded++
            console.log('Loaded '+self.assetsLoaded+' of '+self.assetsCount)
            self.checkLoaded()
        },
        function(xhr) {
            // called while loading is progressing
            // console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`) // eslint-disable-line
        },
       function(error) {
            // called when loading has errors
            console.error('An error happened', error) // eslint-disable-line
        }
    )
}

InfiniteCity.prototype.checkLoaded = function() {
    var self = this
    if (self.assetsLoaded === self.assetsCount) {
        self.createBlocks()
    }
}

InfiniteCity.prototype.createBlocks = function() {
    var self = this
    self.floor = new THREE.Group()
    var  floorMaterial = new THREE.MeshBasicMaterial( { color: 0x000000 } );
    var floorGeometry = new THREE.PlaneGeometry(
        FLOOR_SIZE,
        FLOOR_SIZE,
        FLOOR_SIZE
    )

    for (var i = 0; i < GRID_SIZE; i++) {
        for (var k = 0; k < GRID_SIZE; k++) {
            // add plane
            var plane = new THREE.Group()
            plane.position.x = -FLOOR_SIZE * k
            plane.position.z = -FLOOR_SIZE * i

            // add random block
            var rand = Math.floor(Math.random() * self.blocksLoaded.length)
            // console.log(rand)
            var block = self.blocksLoaded[rand]
            block = block.scene.children[0].clone()
            block.scale.set(2.5, 2.5, 2.5)
            block.rotateY(Math.floor(Math.random()*5) * Math.PI / 2) // random rotation
            block.position.set(FLOOR_SIZE/5, 0, 0)
            plane.add(block)

            // add road square
            var square = self.squaresLoaded[0]
            square = square.scene.children[0].clone()
            square.scale.set(2.5, 2.5, 2.5)
            square.position.set(FLOOR_SIZE/1.425, 0, -FLOOR_SIZE/2)
            plane.add(square)

            // add right road
            var rightRoad = self.roadsLoaded[0]
            rightRoad = rightRoad.scene.children[0].clone()
            rightRoad.scale.set(2.5, 2.5, 2.5)
            rightRoad.position.set(FLOOR_SIZE/1.425, 0, 0)
            plane.add(rightRoad)

            // add left road
            var leftRoad = self.roadsLoaded[0]
            leftRoad = leftRoad.scene.children[0].clone()
            leftRoad.scale.set(2.5, 2.5, 2.5)
            leftRoad.rotateY(Math.PI / 2)
            leftRoad.position.set(FLOOR_SIZE/5, 0, -FLOOR_SIZE/2)
            plane.add(leftRoad)

            // add plane as floor group item to floor group
            self.floor.add(plane)
        }
    }

    self.floor.position.x = (GRID_SIZE * FLOOR_SIZE) / 2
    self.floor.position.y = 0
    self.floor.position.z = (GRID_SIZE * FLOOR_SIZE) / 2

    // self.floor.rotation.z = Math.PI
    self.scene.add(self.floor)
    //
    self.floorGroup = self.floor.children.reverse()

    // start render
    self.render()
    self.resize()

    // play
    TweenMax.fromTo(self.camera, 5, {
        fov: 1.5 * self.camera.fov
    },{
        fov: self.zoom * self.camera.fov,
        ease: Expo.easeInOut,
        delay: 0,
        onUpdate: function(){
            self.camera.updateProjectionMatrix()
        }
    })
}

InfiniteCity.prototype.move = function(array, from, to) {
    if (to >= array.length) {
        var k = to - array.length + 1
        while (k--) {
            array.push(undefined)
        }
    }
    array.splice(to, 0, array.splice(from, 1)[0])
}

InfiniteCity.prototype.render = function() {
    var self = this
    self.controls.update()
    window.requestAnimationFrame(function(){
        self.render()
    })

    // update stage center values
    self.stagecenter.x = self.camera.position.x - (FLOOR_SIZE * -1.5)
    self.stagecenter.z = self.camera.position.z - FLOOR_SIZE * (GRID_SIZE / 3)

    var animateCam = false

    if (animateCam === true) {
        self.camera.position.x += 30
        self.camera.position.z += 30
        self.stagecenter.x = self.camera.position.x - (FLOOR_SIZE * -1.5)
        self.stagecenter.z = self.camera.position.z - FLOOR_SIZE * (GRID_SIZE / 3)
        self.camera.updateProjectionMatrix()
    }

    if (
        self.stagecenter.x > self.lastCenterPos.x + STEP_SIZE &&
        self.stagecenter.x > self.lastCenterPos.x
    ) {
        // direction right
        // console.log('positive x') // eslint-disable-line
        // reset point
        self.lastCenterPos.x = self.stagecenter.x

        for (var k = 0; k < GRID_SIZE; k++) {
            if (k % GRID_SIZE || k === 0) {
                var tile = self.floorGroup[k * GRID_SIZE]
                // shift first column of modified array to the x
                tile.position.x += GRID_SIZE * FLOOR_SIZE
                // replicate shift in array
                self.move(
                    self.floorGroup,
                    k * GRID_SIZE,
                    k * GRID_SIZE + GRID_SIZE - 1
                )
            }
        }
    }
    else if (
        self.stagecenter.x < self.lastCenterPos.x + STEP_SIZE / -1 &&
        self.stagecenter.x < self.lastCenterPos.x
    ) {
        // direction left
        // console.log('negative x') // eslint-disable-line
        // reset point
        self.lastCenterPos.x = self.stagecenter.x

        for (var k = 0; k < GRID_SIZE; k++) {
            if (k % GRID_SIZE || k === 0) {
                var tile =
                    self.floorGroup[k * GRID_SIZE + GRID_SIZE - 1]
                // shift last column of modified array to the x
                tile.position.x -= GRID_SIZE * FLOOR_SIZE
                // replicate shift in array
                self.move(
                    self.floorGroup,
                    k * GRID_SIZE + GRID_SIZE - 1,
                    k * GRID_SIZE
                )
            }
        }
    }
    else if (
        self.stagecenter.z > self.lastCenterPos.z + STEP_SIZE &&
        self.stagecenter.z > self.lastCenterPos.z
    ) {
        // direction down
        // console.log('positive z') // eslint-disable-line
        // reset point
        self.lastCenterPos.z = self.stagecenter.z

        for (var i = 0; i < GRID_SIZE; i++) {
            var tile = self.floorGroup[i]
            // shift first row to the z
            tile.position.z += GRID_SIZE * FLOOR_SIZE
            // replicate shift in array
            if (i === GRID_SIZE - 1) {
                for (var k = 0; k < GRID_SIZE; k++) {
                    self.floorGroup.push(self.floorGroup.shift())
                }
            }
        }
    }
    else if (
        self.stagecenter.z < self.lastCenterPos.z + STEP_SIZE / -1 &&
        self.stagecenter.z < self.lastCenterPos.z
    ) {
        // direction up
        // console.log('negative z') // eslint-disable-line
        // reset point
        self.lastCenterPos.z = self.stagecenter.z

        for (var i = 0; i < GRID_SIZE; i++) {
            // console.log(((GRID_SIZE * GRID_SIZE) - GRID_SIZE)  +  i) // eslint-disable-line
            // console.log(GRID_SIZE * GRID_SIZE - GRID_SIZE + i) //eslint-disable-line
            var lastRowPos = GRID_SIZE * GRID_SIZE - GRID_SIZE + i
            var tile = self.floorGroup[lastRowPos]
            // shift first row to the z
            tile.position.z -= GRID_SIZE * FLOOR_SIZE
            // replicate shift in array
            self.move(
                self.floorGroup,
                GRID_SIZE * GRID_SIZE - GRID_SIZE + i,
                i
            )
        }
    }
    self.raycaster.setFromCamera(self.mouse, self.camera);
    self.renderer.render(self.scene, self.camera)
}

InfiniteCity.prototype.resize = function() {
    var self = this
    self.camera.aspect = self.container.clientWidth / self.container.clientHeight
    self.camera.updateProjectionMatrix()
    self.renderer.setSize(
        self.container.clientWidth,
        self.container.clientHeight
    )
}


module.exports = InfiniteCity;
